<script lang="ts" setup>
import { PropType } from 'vue'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { modifiersConfig } from '~~/src/config/Modifiers'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { inputFieldConfig } from '~~/src/constants/inputField'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { TweakModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { NumericVariationType } from '~~/src/submodules/sharedTypes/common/NumericVariationType'
import { utilCurrency } from '~~/src/utils/utilCurrency'
import StateToggler from '../../common/StateToggler.vue'

const props = defineProps({
	currentValue: {
		type: Object as PropType<TweakModifierType>,
		default: {
			variationType: NumericVariationType.Amount,
			variationValue: 0,
		},
	},
	originalValue: {
		type: Object as PropType<TweakModifierType>,
		default: {
			variationType: NumericVariationType.Amount,
			variationValue: 0,
		},
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	currency: { type: Object as PropType<Currency>, required: false },
	hasError: { type: Boolean, default: false },
	isCompact: { type: Boolean },
	testIds: {
		type: Object as PropType<{ 'tweak-left-button'?: string; 'tweak-right-button'?: string; input?: string }>,
		default: () => ({}),
	},
})

const { currentValue, originalValue, isActive, currency, hasError, testIds } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Delete])

onMounted(() => {
	if (!currency?.value) {
		currentValue.value.variationType = NumericVariationType.Percentage
	}
})

const currencySymbol = computed(() => (currency?.value ? utilCurrency.currencySymbol(currency.value) : ''))
const currencyIcon = computed(() => (currency?.value ? utilCurrency.currencyIcon(currency.value) : ''))
const sign = ref(currentValue.value?.variationValue < 0 ? 1 : 0)
const onSignChange = (val: number) => {
	sign.value = val

	// TODO: improve
	// given we only have + and -, stored as: [+,-], we can just calculate the multiplier like this.
	const multiplier = val == 0 ? 1 : -1

	const returnObject: TweakModifierType = {
		...currentValue.value,
		variationValue: Math.abs(currentValue.value.variationValue) * multiplier,
	}
	emit(EmitsEnum.Change, returnObject)
}

const variationTypes = [NumericVariationType.Amount, NumericVariationType.Percentage]
const index = Math.max(
	0,
	variationTypes.findIndex((el) => el === currentValue.value?.variationType)
)
const type = ref(index)
const onTypeChange = (val: number) => {
	if (!currency?.value) {
		return
	}

	type.value = val
	if (variationTypes[val] == NumericVariationType.Percentage) {
		currentValue.value.variationValue = currentValue.value.variationValue * 0.01
	} else {
		currentValue.value.variationValue = Math.round(currentValue.value.variationValue * 100)
	}

	const returnObject: TweakModifierType = {
		...currentValue.value,
		variationType: variationTypes[val],
	}
	emit(EmitsEnum.Change, returnObject)
}

const variationValue = computed(() =>
	Math.abs(
		currentValue.value.variationType === NumericVariationType.Percentage
			? Math.round(currentValue.value.variationValue * 100)
			: currentValue.value.variationValue
	)
)

const onValueChange = (event: any) => {
	if (event.data === null) {
		const returnObject: TweakModifierType = {
			...currentValue.value,
			variationValue: 0,
		}

		emit(EmitsEnum.Change, returnObject)
		return
	}

	const val = parseInt(event.target.value)

	if (['+', '-'].includes(event.data)) {
		event.preventDefault()
		return
	}

	if (!Number.isNaN(val)) {
		const multiplier = sign.value == 0 ? 1 : -1
		const percentageMultiplier = currentValue.value.variationType === NumericVariationType.Percentage ? 100 : 1

		const returnObject: TweakModifierType = {
			...currentValue.value,
			variationValue: (val * multiplier) / percentageMultiplier,
		}

		emit(EmitsEnum.Change, returnObject)
	} else if (event.target.value == '') {
		emit(EmitsEnum.Delete)
		return
	} else {
		currentValue.value = originalValue.value
	}
}

const allowedStates = computed(() => {
	if (!currency || !currency?.value) {
		return [{ icon: SpSvg.CalendarPercentSmall }]
	}

	return [{ icon: currencySymbol.value, asChar: true }, { icon: SpSvg.CalendarPercentSmall }]
})

const background = computed(() => (isActive.value ? modifiersConfig.tweak.activeBackground : 'bg-white'))

const inputField = ref()
const onFocus = () => inputField.value.select()

const valueToDisplay = computed(() => (variationValue.value == 0 ? undefined : variationValue.value.toString()))

onMounted(() => {
	const returnObject: TweakModifierType = {
		...currentValue.value,
	}
})

const resetState = () => {
	sign.value = 0
	type.value = 0
}

defineExpose({
	resetState,
})
</script>

<template>
	<div class="relative flex items-center" :class="background">
		<div class="flex w-full items-center justify-between gap-2">
			<StateToggler
				:allowed-states="[{ icon: SpSvg.CalendarPlusSmall }, { icon: SpSvg.CalendarMinusSmall }]"
				:selected-val="sign"
				:data-testid="testIds?.['tweak-left-button']"
				@click="onSignChange"
			/>
			<input
				ref="inputField"
				type="number"
				class="text-right text-sm placeholder:text-dark-blue-400"
				@input.stop="onValueChange"
				:value="valueToDisplay"
				:class="background"
				:placeholder="inputFieldConfig.numericPlaceholder"
				@focusin="onFocus"
				:style="`width: ${isCompact ? '100%' : 'inherit'};`"
				:data-testid="testIds?.['input']"
			/>

			<StateToggler
				:states-as-char="true"
				:allowed-states="allowedStates"
				:selected-val="type"
				@click="onTypeChange"
				:data-testid="testIds?.['tweak-right-button']"
			/>
			<CommonIcon v-if="hasError" :icon-name="SpSvg.BasicErrorCircle" :icon-size="IconSizes.XS" class="fill-red-500" />
		</div>
	</div>
</template>
