<script lang="ts" setup>
import { PropType } from 'vue'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'

type AllowedStates = ({ icon: SpSvg; asChar?: false } | { icon: string; asChar: true })[]

const props = defineProps({
	statesAsChar: { type: Boolean, default: false },
	allowedStates: { type: Object as PropType<AllowedStates>, required: true },
	selectedVal: { type: Number, default: 0 },
})
const { allowedStates: states, selectedVal } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])

const onClick = () => {
	const newVal = (selectedVal.value + 1) % states.value.length
	emit(EmitsEnum.Click, newVal)
}

const background = (index: number) => (index === selectedVal.value ? 'bg-dark-blue-600' : '')
const iconColor = (index: number) =>
	index === selectedVal.value ? 'fill-white text-white' : 'fill-dark-blue-400 text-dark-blue-400'

const circleRef = ref()
const circleHeight = computed(() => circleRef?.value?.at(0)?.clientHeight || 0)
</script>

<template>
	<div class="bg-dark-blue-100 rounded-full flex p-0.5 cursor-pointer" @click="onClick">
		<div
			v-for="(val, index) in allowedStates"
			class="rounded-full p-1.5 flex justify-center"
			:class="background(index)"
			ref="circleRef"
			:style="`min-width: ${circleHeight}px;`"
		>
			<p
				v-if="val.asChar"
				:class="iconColor(index)"
				class="h-[12px] text-[12px] font-[700] inline leading-none text-center"
				:title="val.icon"
			>
				{{ val.icon }}
			</p>
			<CommonIcon v-else :icon-name="val.icon" :class="iconColor(index)" :icon-size="IconSizes.XS" />
		</div>
	</div>
</template>

