import { Accommodation } from '@/types/Accommodation'
import { DateRange } from '../submodules/sharedTypes/common/DateRange'
import { utilDate } from './utilDate'
class UtilCalendar {
	datesToHighlightedDates(
		dates: Date[],
		color: string, //  'purple',
		fillMode: string = 'light'
	) {
		return dates.map((el) => ({
			highlight: {
				color,
				fillMode,
			},
			dates: el,
		}))
	}

	dateRangesToHighlightedDates(
		dates: DateRange[],
		color: string, //  'purple',
		fillMode: string = 'light'
	) {
		return dates.map((el) => ({
			highlight: {
				color,
				fillMode,
			},
			dates: utilDate.dateRangeToDateArray(el),
		}))
	}

	roomTypesMap<T = any>(accommodations: Accommodation[]) {
		const roomTypesMap = new Map<string, T[]>()
		accommodations.forEach((accommodation) => {
			accommodation.roomTypes?.forEach((roomType) => {
				roomTypesMap.set(roomType.id.toString(), [])
			})
		})
		return roomTypesMap
	}
}

export const utilCalendar = new UtilCalendar()
